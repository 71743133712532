@import '/node_modules/react-multi-carousel/lib/styles.css';

.react-multi-carousel-dots-list {
	bottom: 14vh;
}

.react-multi-carousel-dot--active button {
	background: $carousel-fs-dot-active-color;
}

.react-multiple-carousel__arrow:hover {
	background-color: $carousel-fs-arrow-hover-color;
}

.react-multiple-carousel__arrow:focus {
	outline: none;
}

.react-multi-carousel-dot button:hover {
	border-color: $carousel-fs-dot-active-border-hover-color;
}

.react-multi-carousel-dot-list.react-multi-carousel-dots-list
.react-multi-carousel-dot button:focus {
	background-color: $carousel-fs-dot-active-background-hover-color;
}

.react-multi-carousel-image {
	margin-top: -6vh;
	width: 100%;
	text-align: center;
}