.sidebar {
  align-items: center;
  background-color: $sidebar-background-color;
  box-shadow: $sidebar-box-shadow;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  justify-content: space-between;
  padding-top: 20vh;
  position: fixed;
  top: 0;
  transition: width .2s, background-color .2s;
  width: $sidebar-width;
  z-index: $sidebar-z-index;

  & .menu__close {
    display: none;
  }

  &__logo {
    flex-basis: 20%;
    align-items: center;
    display: flex;
    justify-content: center;

    & img {
      width: 70%;
    }
  }

  &--hide {
    // box-shadow: none;
    width: $sidebar-close-width;

    &:hover {
      background-color: $sidebar-hover-background-color;
    }

    & .menu__open {
      display: none;
      transition: display 0.5s ease-in;
    }

    & .sidebar__logo img {
      width: 4vw;
    }

    & .menu__close {
      display: flex;
    }
  }

  &__overlay {
    background-color: $sidebar-overlay-background-color;
    height: 100vh;
    left: 0;
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: $sidebar-z-index-overlay;

    &--hide {
      display: none;
    }
  }
}

@media( prefers-reduced-motion: reduce ) {
  .sidebar {
    transition: none;

    &--hide {
      & .menu__open,
      & .sidebar__logo {
        transition: none;
      }
    }
  }
}