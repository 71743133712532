.text {
  font-family: 'DMSansRegular';
  font-size: $text-font-size;
  color: $text-color;
  line-height: $text-line-height;

  & > span {
    margin-bottom: $text-p-margin-bottom;
  }

  &--secondary {
    color: $text-color-secondary;
  }

  &--important {
    color: $text-color-important;
  }

  &--error {
    color: $color-danger;
  }

  &--large {
    font-size: $text-large-font-size;
  }

  &--small {
    font-size: $text-small-font-size;
  }

  &--tiny {
    font-size: $text-tiny-font-size;
  }

  &--bold {
    font-weight: 700;
  }

  &--center {
    text-align: center;
  }

  &--shadow {
    text-shadow: $listitem-thumb-text-shadow;
  }

  &--no-lineheight {
    line-height: 1.2;
  }

  &--inline {
    display: inline;
  }
}