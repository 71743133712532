.carousel {
  border-radius: $carousel-border-radius;
  transition: all .2s ease-in-out;

  &-root {
    box-shadow: 0 2.4vh 2.8vh -1vh $menuitem-shadow-color;
  }
  
  &--active {
    border: $carousel-active-border;
    // box-shadow: 0px 25px 30px -10px $menuitem-shadow-color;
    cursor: pointer;

    & .carousel .control-dots .dot {
      background: $carousel-dot-active;
    }
  }

  & .text {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50% , -50%);
    -webkit-transform: translate(-50%, -50%);
    line-height: 1.5;
    z-index: 30;
  }

  & .loader__wrapper {
    position: absolute;
    top: 35%;
  }
}

@media( prefers-reduced-motion: reduce ) {
  .carousel {
    transition: none;
  }
}