.catalog {
  height: 100vh;
  padding-bottom: $padding-bottom-pages;
  overflow-y: scroll;
  padding-left: $sidebar-close-width + $padding-left-catalog;
  scroll-behavior: smooth;
  width: 100vw;
  @include hide-scrollbar();

  &__loader {
    height: 75vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

