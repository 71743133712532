@import 'colors';

$color-primary: $natural-gray;
$color-secondary: $white;
$color-brand-primary: $disco;
$color-brand-primary-dark1: $revolver;
$color-brand-primary-dark2: $haiti;
$color-brand-primary-darkest: $jaguar;
$color-brand-secondary: $california;
$color-gray-dark: $mine-shaft;
$color-dark: $black;
$color-danger: $flamingo;

// Padding
$padding-none: 0;
$padding-bottom-pages: 4vh;
$padding-left-menu-item: 5vh;
$padding-left-catalog: 1vw;
$padding-wrapper: 4vh 0 0 2vw;

// Margin
$margin-bottom-menu-item-separator: 7vh;
$margin-form-group: 2vh;
$margin-right-menu-icon: 0.5vw;


// Absolute values
$absolute-bottom: 0;

// Width
$fullscreen-width: 100vw;


/** ----------------------
 *  ----- COMPONENTS -----
 ---------------------- */

// Arrow
$arrow-left-margin-left: -$padding-left-catalog;
$arrow-gradient-color: $color-brand-primary-dark1;
$arrow-hover-color: $color-brand-primary;

// Button
$button-background-color: $color-brand-primary-dark1;
$button-border-color: $color-brand-primary-dark1;
$button-border: 0.3vw solid $button-border-color;
$button-border-radius: 1vw;
$button-margin-bottom: 3vh;
$button-text-color: $color-secondary;
$button-focus-border-color: $color-secondary;
$button-focus-background-color: $color-brand-primary;
$button-big-font-size: 3.5vh;
$button-big-padding: 2vh 5vw;
$button-giant-font-size: 4vh;
$button-giant-padding: 2vh 0;
$button-medium-padding: 1vh 1vw;

$button-secondary-background-color: $color-gray-dark;
$button-secondary-border-color: $color-gray-dark;
$button-secondary-border: 0.4vw solid $button-secondary-border-color;
$button-secondary-text-color: $color-secondary;


// Carousel
$carousel-shadow-color: $color-brand-primary-darkest;
$carousel-dot-active: $color-brand-primary;
$carousel-active-border-color: $color-secondary;
$carousel-active-border: 0.4vw solid $carousel-active-border-color;
$carousel-border-radius: 0.5vw;

// Carousel fullscreen
$carousel-fs-arrow-hover-color: $color-brand-primary; 
$carousel-fs-dot-active-color: $color-brand-primary;
$carousel-fs-dot-active-border-hover-color: $color-brand-primary-dark1;
$carousel-fs-dot-active-background-hover-color: $color-brand-primary-dark1;

// Icon
$icon-back-color: $color-brand-primary;
$icon-clickable-hover-shadow-color: $color-brand-primary;
$icon-important-color: $color-brand-primary;
$icon-primary-color: $color-primary;
$icon-secondary-color: $color-secondary;
$icon-purchased-border-radius: 0.3vw;
$icon-purchased-fill-color: $color-secondary;
$icon-purchased-background-color: rgba($color-brand-secondary, 0.5);
$icon-purchased-padding: 0.6vh 0;
$icon-purchased-width: 2.6vw;


// Input
$input-box-shadow-color: $color-brand-primary-darkest;
$input-border-color: $color-primary;
$input-border-width: .1vw;
$input-border: $input-border-width solid $input-border-color;
$input-focus-border-color: $color-secondary;
$input-hover-not-focus-border-color: $color-brand-primary;
$input-background-color: $color-brand-primary-darkest;
$input-border-radius: 0.5vw;
$input-search-height: 11vh;

// Label
$label-color: $color-primary;
$label-focus-color: $color-secondary;

//Listitem
$listitem-height: 24vh;
$listitem-width: 40vw;
$listitem-margin-right: 1vw;
$listitem-thumb-height: 20vh;
$listitem-thumb-width: 20vw;
$listitem-thumb-text-shadow-color: $color-primary;
$listitem-thumb-text-shadow: 0 0 1.2vw $listitem-thumb-text-shadow-color;
$listitem-thumb-background-color: $color-primary;
$listitem-thumb-border-radius: 0.5vw;
$listitem-thumb-margin-right: 1vw;
$listitem-text-height: 20vh;
$listitem-text-width: 20vw;
$listitem-active-border-color: $color-secondary;
$listitem-active-border-size: 0.4vw;
$listitem-active-border: $listitem-active-border-size solid $listitem-active-border-color;
$listitem-grid-thumb-height: 15vh;
$listitem-grid-thumb-width: 15vw;
$listitem-grid-height: 19vh;
$listitem-grid-width: 30vw;

// List
$list-padding-top-bottom: 1vh;
$list-padding-left-right: 1vw;
$list-padding: $list-padding-top-bottom $list-padding-left-right;
$list-hz-padding-right: 1vw;
$list-arrows-height: $listitem-thumb-height * 1.1;
$list-arrows-z-index: 90;

// Loader
$loader-border-color: $color-brand-primary;

// Menu
$menu-close-icon-selected-color: $color-primary;

// Menuitem
$menuitem-selected-color: $color-secondary;
$menuitem-selected-background-color: $color-brand-primary;
$menuitem-active-color: $color-secondary;
$menuitem-active-background-color: $natural-gray;
$menuitem-shadow-color: $color-brand-primary-darkest;
$menuitem-icon-color: $color-primary;
$menuitem-color: $color-primary;
$menuitem-height: 10vh;

// Pill
$pill-margin-left: 1vw;

// Popup
$popup-border-radius: 0.5vw;
$popup-overlay-background: rgba($color-brand-primary-darkest, 0.4);
$popup-content-background: $color-gray-dark;
$popup-z-index: 120;
$popup-text-color: $color-secondary;
$popup-padding: 2vh 1vw;

// Reader
$reader-bar-background: $color-brand-primary;
$reader-bar-height: 10vh;
$reader-bar-border-radius: $reader-bar-height / 2;
$reader-bar-margin-top: 4vh;
$reader-bar-width: 90vw;
$reader-progressbar-marker-height: 2vh;
$reader-progressbar-height: 1vh;
$reader-progressbar-width: 45vw;
$reader-progressbar-padding-hz: ( $reader-progressbar-marker-height - $reader-progressbar-height ) / 2; 
$reader-progressbar-padding: $reader-progressbar-padding-hz 0;
$reader-progressbar-border-radius: $reader-progressbar-height / 2;
$reader-progressbar-marker-background :$color-secondary;
$reader-progressbar-marker-border-radius: $reader-progressbar-marker-height / 2;
$reader-button-back-z-index: 30;

// Sidebar
$sidebar-background-color: $color-brand-primary-dark2;
$sidebar-hover-background-color: $color-brand-primary-dark1;
$sidebar-overlay-background-color: rgba($color-dark, 0.60);
$sidebar-box-shadow-color: $color-dark;
$sidebar-box-shadow: 0.3vw 0 0.8vw 0.1vw $sidebar-box-shadow-color;
$sidebar-z-index: 100;
$sidebar-z-index-overlay: 90;
$sidebar-width: 20vw;
$sidebar-close-width: 6vw;

// Splashscreen
$splashscreen-z-index: 130;

// Text
$text-tiny-font-size: 1.8vh;
$text-small-font-size: 2.2vh;
$text-font-size: 3vh;
$text-large-font-size: 6vh;
$text-line-height: 3vh;
$text-p-margin-bottom: 1vh;
$text-color-important: $color-brand-primary;
$text-color: $color-primary;
$text-color-secondary: $color-secondary;
$text-color-important: $color-brand-secondary;

// Title
$title-1-font-size: 8vh;
$title-2-font-size: 5vh;
$title-3-font-size: 3vh;
$title-4-font-size: 3vh;
$title-5-font-size: 3vh;
$title-6-font-size: 3vh;
$title-1-margin-bottom: 2vh;
$title-1-margin-top: 2vh;
$title-3-margin-top: 5vh;
$title-4-margin-bottom: 0.5vh;
$title-color-secondary: $color-secondary;

// Wrapper
$wrapper-overlay-background-color: rgba($color-dark, 0.6);
$wrapper-gradient-left-background-color: $color-dark;
$wrapper-gradient-bckgnd-background-color: $color-dark;
$wrapper-gradient-bottom-background-color: $color-brand-primary-darkest;
$wrapper-sticky-bottom-mb: 4vh;
$wrapper-z-index: 90;
$wrapper-sticky-bottom: 0;