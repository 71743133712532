#Icon_ionic-ios-play {
  margin-left: 1vw;
}
.reader {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;

  &__button {
    &--back {
      position: absolute;
      top: 4vh;
      left: 2vw;
      -webkit-box-shadow: 0px 14px 40px -15px #000000; 
      box-shadow: 0px 14px 40px -15px #000000;
      z-index: $reader-button-back-z-index;
    }
  }

  & .stf__parent {
    // position: relative;
    z-index: 10;

    // &.--outer-shadow {
    //   &:after {
    //     content: '';
    //     position: absolute;
    //     height: 100%;
    //     width: 100%;
    //     top: 0;
    //     left: 0;
    //     box-shadow: 5px 5px 15px 5px rgba(255, 255, 255, 1);
    //     z-index: -1;
    //   }

    //   &.--left:after {
    //     transform: scaleX(0.5);
    //     transform-origin: left;
    //   }

    //   &.--right:after {
    //     transform: scaleX(0.5);
    //     transform-origin: right;
    //   }
    // }

    & .stf__block .stf__item {
      //:before => shadows
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:first-child:before {
        background: linear-gradient(90deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.4) 3%, rgba(255,255,255,0.2) 5%, rgba(255,255,255,0) 100%);
      }

      &:last-child:before {
        background: linear-gradient(270deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 1%, rgba(0,0,0,0.4) 3%, rgba(255,255,255,0.2) 5%, rgba(255,255,255,0) 100%);
      }
  
      &:not(:first-child):not(:last-child) {
        &.--right:before {
          background: linear-gradient(90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 4%, rgba(255,255,255,0.03) 8%, rgba(255,255,255,0.12) 18%, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.05) 69%, rgba(0,0,0,0.1) 88%, rgba(0,0,0,0) 100%);  
        }    
        &:not(.--right):before {
          background: linear-gradient(270deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.1) 4%, rgba(255,255,255,0.03) 8%, rgba(255,255,255,0.12) 18%, rgba(255,255,255,0.1) 30%, rgba(255,255,255,0.05) 69%, rgba(0,0,0,0.1) 88%, rgba(0,0,0,0) 100%);  
        }
      }

      //:after => page thickness
      &:after {
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 25%, rgba(0,0,0,0.9) 50%, rgba(0,0,0,0.75) 75%, rgba(0,0,0,0) 100%);
        content: '';
        height: 100%;
        position: absolute;
        top: 0;
        -webkit-transform: rotate3d(0,1,0,-90deg);
        -moz-transform: rotate3d(0,1,0,-90deg);
        transform: rotate3d(0,1,0,-90deg);
      }

      &:first-child, 
      &.--left.--hard {
        &:after {
          width: 6px;
        }
       
        &.--left:after { left: -3px;}
        &.--right:after { right: -3px; }
      }
    }    
  }

  &__bar {
    align-items: center;
    background: $reader-bar-background;
    border-radius: $reader-bar-border-radius;
    display: flex;
    height: $reader-bar-height;
    justify-content: center;
    margin-top: $reader-bar-margin-top;
    width: $reader-bar-width;

    & > * {
      margin-bottom: 0;
    }

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      & .--loading {
        -webkit-animation:spin 1s linear infinite;
        -moz-animation:spin 1s linear infinite;
        animation:spin 1s linear infinite;
      }
    }
  }

  &__progress {
    &-bar,
    &-text {
      display: inline-block;
    }
    
    &-text {
      margin-right: 0.5vw;
    }

    &-bar {
      height: $reader-progressbar-marker-height;
      margin-right: 1vw;
      padding: $reader-progressbar-padding;
      position: relative;
      width: $reader-progressbar-width;
      z-index: 5;
      
      &__background {
        border-radius: $reader-progressbar-border-radius;
        background: $color-primary;
        display: block;
        height: 100%;
        width: 100%;
      }
      
      &__marker {
        display: block;
        background: $reader-progressbar-marker-background;
        border-radius: $reader-progressbar-marker-border-radius;
        height: $reader-progressbar-marker-height;
        left: 0;
        position: absolute;
        top: 0;
        width: $reader-progressbar-marker-height;
      }
    }
  }
}