.title {
  color: $color-primary;
  font-family: 'DMSansMedium';

  &--secondary {
    color: $title-color-secondary;
  }

  &--center {
    text-align: center;
  }

  &--lite {
    font-family: 'DMSansRegular';
  }

  &--1 {
    font-size: $title-1-font-size;
    margin-top: $title-1-margin-top;
    margin-bottom: $title-1-margin-bottom;
  }

  &--2 {
    font-size: $title-2-font-size;
  }

  &--3 {
    font-size: $title-3-font-size;
    margin-top: $title-3-margin-top;
  }

  &--4 {
    font-size: $title-4-font-size;
    margin-bottom: $title-4-margin-bottom;
  }

  &--5 {
    font-size: $title-5-font-size;
  }

  &--6 {
    font-size: $title-6-font-size;
  }
}
