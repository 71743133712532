// @mixin typo-balboa-light {
//   font-family: Balboa, sans-serif;
//   font-style: normal;
//   font-weight: 300;
// }

// @mixin typo-balboa-medium {
//   font-family: Balboa, sans-serif;
//   font-style: normal;
//   font-weight: 400;
// }

@font-face {
  font-family: 'DMSansRegular';
  src: url('../../assets/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DMSansMedium';
  src: url('../../assets/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DMSansBold';
  src: url('../../assets/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}