.wrapper {
  z-index: $wrapper-z-index;
  width: $fullscreen-width;
  padding: $padding-wrapper;

  &--sticky-bottom,
  &--sticky-bottom-mb {
    position: absolute;
    bottom: $wrapper-sticky-bottom;  
  }

  &--sticky-bottom-mb {
    margin-bottom: $wrapper-sticky-bottom-mb;
  }

  &--padding-none {
    padding: $padding-none;
  }

  &--centered {
    margin: 4vh auto 6vh auto;
    padding: 0 3vw;
    width: 50%;
  }

  &--size-half {
    width: 50%;
  }

  &--size-two-third {
    width:66.66666%;
  }

  &--overlay {
    background: $wrapper-overlay-background-color;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;

    &-gl {
      background: linear-gradient(90deg, $wrapper-gradient-left-background-color 10%, $transparent 100%);
    }

    &-gbckgnd {
      background: linear-gradient(0deg, rgba($wrapper-gradient-bckgnd-background-color, 0.75)0%, rgba($black, 0.45) 100%);
    }

    &-gbottom {
      background: linear-gradient(0deg, rgba($wrapper-gradient-bottom-background-color, 1) 26%, rgba(255,255,255,0) 100%);    }
  }
}