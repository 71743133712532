.form__label {
  color: $label-color;
  font-size: 3.5vh;
  font-family: "DMSansRegular";
  float: left;
  line-height: 1.5;

  &--focus {
    color: $label-focus-color;
  }
}