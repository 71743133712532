$white: rgb(255, 255, 255);
$natural-gray: rgb(148,147,146);
$mine-shaft: rgb(31,31,31);
$black: rgb(0, 0, 0);
$disco: rgb(104, 22, 88);
$revolver: rgb(46, 20, 57);
$haiti: rgb(28, 10, 42);
$jaguar: rgb(14, 2, 23);
$california: rgb(251, 160, 11);
$flamingo: rgb(239, 70, 70);
$transparent: transparent;
