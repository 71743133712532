.arrow {
  &--left {
    margin-left: $arrow-left-margin-left;
  }

  &--gradient {
    &.arrow--left {
      background: linear-gradient(90deg, $arrow-gradient-color 0%, rgba($arrow-gradient-color, 0.5) 33%, $transparent 100%);

      &:hover,
      &:active {
        background: linear-gradient(90deg, $arrow-gradient-color 0%, rgba($arrow-gradient-color,0.5) 67%, $transparent 100%);
      }
    }

    &.arrow--right {
      background: linear-gradient(270deg, $arrow-gradient-color 0%, rgba($arrow-gradient-color, 0.5) 33%, $transparent 100%);

      &:hover,
      &:active {
        background: linear-gradient(270deg, $arrow-gradient-color 0%, rgba($arrow-gradient-color,0.5) 67%, $transparent 100%);
      }
    }
  }

  &--hide {
    display: none;
  }
}
