.category {
  //overflow-x: hidden;
  padding-top: 2vw;

  & .title {
    margin-top: 0;
  }

  & .category__arrow__container {
    position: relative;
  }

  & .arrow {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: $list-arrows-height;
    justify-content: center;
    position: absolute;
    top: $list-padding-top-bottom - $listitem-thumb-height * 0.05;
    width: 2.5vw;
    z-index: $list-arrows-z-index;

    &--right {
      right: 0;
    }

    &:hover .icon,
    &:active .icon {
      fill: $arrow-hover-color;
    }
  }
}