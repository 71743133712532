.menu {
  width: 100%;
  // margin-top: 3vh;

  &__close {
    display: flex;
    flex-direction: column;
    align-items: center;

    & .icon {
      box-sizing: content-box;
      padding: 3vh 0;
      width: 2vw;
      height: 2vw;

      &--selected {
        fill: $menu-close-icon-selected-color;
      }

      &--separator {
        margin-bottom: $margin-bottom-menu-item-separator;
      }
    }
  }
}
