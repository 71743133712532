.listitem {
  &__content {
    align-items: flex-start;
    display: inline-flex;
    font-family: 'DMSansRegular';
    height: $listitem-height;
    justify-content: space-between;
    margin-right: $listitem-margin-right;
    width: $listitem-width;
  }

  &--active {
    cursor: pointer;

    & .listitem__thumb {
      transform: scale(1.05); //TODO variable
      transform-origin: center;

      &__img {
        border: $listitem-active-border;
      }

      & .icon-live {
        right: $listitem-active-border-size;
        top: $listitem-active-border-size;
      }

      & .icon-purchased {
        left: $listitem-active-border-size;
        top: $listitem-active-border-size;
      }
    }
  }

  &--grid {
    .listitem__content {
      align-items: center;
      height: $listitem-grid-height;
      width: $listitem-grid-width;
    }

    .listitem__thumb {
      height: $listitem-grid-thumb-height;
      width: $listitem-grid-thumb-width;

      & img,
      & .loader__wrapper {
        height: $listitem-grid-thumb-height;
        width: $listitem-grid-thumb-width;
      }
    }

    .listitem__text {
      flex-direction: column-reverse;
      justify-content: center;
    }
  }

  &__thumb {
    align-items: center;
    background-color: $listitem-thumb-background-color;
    border-radius: $listitem-thumb-border-radius;
    box-shadow: 0px 25px 30px -10px $menuitem-shadow-color;
    display: inline-flex;
    justify-content: center;
    margin-right: $listitem-thumb-margin-right;
    position: relative;
    transition: transform .2s ease-out;
  
    &,
    & &__img,
    & .loader__wrapper {
      height: $listitem-thumb-height;
      width: $listitem-thumb-width;
    }
  
    & .loader__wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 0;
    }
  
    & &__img {
      object-fit: cover;
      border-radius: $listitem-thumb-border-radius;
    }
  
    & .text {
      position: absolute;
      line-height: 1.5;
      text-align: center;
      transform-origin: center;
      z-index: 30;
    }
  
    & .loader__wrapper {
      position: absolute;
    }

    & .icon-live {
      position: absolute;
      right: 0;
      top: 0;
      width: 5vw;
    }

    & .icon-purchased {
      background: $icon-purchased-background-color;
      border-radius: $icon-purchased-border-radius;
      fill: $icon-purchased-fill-color;
      left: 0;
      padding: $icon-purchased-padding;
      position: absolute;
      top: 0;
      width: $icon-purchased-width;
    }
  }

  &__text {
    color: $color-primary;
    display: flex;
    flex-direction: column;
    height: $listitem-text-height;
    width: $listitem-text-width;
  
    &__title {

    }

    &__category {
      &__icon {
        height: 2.2vh;
        &--wrapper {
          vertical-align: middle;
        }
      }
    }
    
    &__date-available {

    }

    &__offer {

    }

    &__description {
      line-height: 0;
    }
  }
}

@media( prefers-reduced-motion: reduce ) {
  .listitem {
    transition: none;
  }
}