.player {
  position: relative;
  height: 100vh;
  width: 100vw;

  &__button-back {
    position: absolute;
    top: 0;
    left: 0;
    padding-left: 2vw;
    padding-top: 4vh;
    z-index: 400;
  }
}