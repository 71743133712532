.image {
	font-family: 'DMSansMedium';

	&--fullscreen {
		position: absolute;
		top: 0;
		height: auto;
		width: 100vw;
		color: $color_secondary;
		font-size: 12vh;
	}
}