.popup {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100vh;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: $popup-z-index;

    &--overlay {
        background: $popup-overlay-background;
    }

    &__content {
        background: $popup-content-background;
        border-radius: $popup-border-radius;
        display: flex;
        flex-direction: column;
        height: 50vh;
        padding: $popup-padding;
        width: 50vw;

        & .button {
            align-self: flex-end;
        }
    }
}