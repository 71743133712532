.list {
  align-items: center;
  display: flex;
  overflow-x: hidden;
  padding: $list-padding;
  scroll-behavior: smooth;

  &__gap {
    padding: $list-hz-padding-right;
  }

  &--wrapped {
    flex-wrap: wrap;
  }
}