.icon {
  fill: $icon-primary-color;
  height: 2vw;
  width: 2vw;
  transition: visibility 0.3s ease-in;

  &--wrapper {
    align-items: center;
    display: flex;
  }

  &--secondary {
    fill: $icon-secondary-color;
  }

  &--brand-primary {
    fill: $icon-important-color;
  }

  &--hide {
    visibility: hidden;
  }

  &--clickable {
    cursor: pointer;

    &:hover, &:active {
      filter: drop-shadow(1px 0px 12px $icon-clickable-hover-shadow-color)
      drop-shadow(1px 0px 3px $color-secondary);
    }
  }
}

@media(prefers-reduced-motion: reduce) {
  .icon {
    transition: none;
  }
}