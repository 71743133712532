.search {
  padding-left: $sidebar-close-width;

  & .wrapper {
    background-color: $color-dark;
    height: $input-search-height;
    left: $sidebar-close-width;
    padding: 3vh 2vw 2vh 2vw;
    position: fixed;
    top: 0;
    width: 94vw;

    & .form__group {
      margin-top: 0;
      height: 100%;

      & .form__input {
        height: 100%;
      }
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh - $input-search-height;
    overflow-y: scroll;
    padding-bottom: $padding-bottom-pages;
    position: relative;
    scroll-behavior: smooth;
    margin-top: $input-search-height;
    @include hide-scrollbar();

    & > div { //Grid
      & > div { //HorizontalList
        padding: 0.5vh 0;
      }
    }

    & .loader__wrapper {
      padding-top: 40vh;
    }

    &__overlay {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 50;

      &--hide {
        display: none;
      }
    }
  }
}