.menuitem {
  align-items: center;
  color: $menuitem-color;
  display: flex;
  height: $menuitem-height;
  padding-left: $padding-left-menu-item;
  position: relative;

  & .icon {
    width: 2vw;
    fill: $menuitem-icon-color;
    margin-right: $margin-right-menu-icon;
  }

  &--selected {
    background-color: $menuitem-selected-background-color;

    & .text {
      color: $menuitem-selected-color;
    }
    & .icon {
      fill: $menuitem-selected-color;
    }
  }

  &--active {
    background-color: $menuitem-active-background-color;
    cursor: pointer;

    & .text {
      color: $menuitem-active-color;
    }

    & .icon {
      fill: $menuitem-active-color;
    }
  }

  &--separator {
    margin-bottom: $margin-bottom-menu-item-separator;
  }
}