.button {
  background: $button-background-color;
  border: $button-border;
  border-radius: $button-border-radius;
  color: $button-text-color;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: $button-margin-bottom;
  font-family: 'DMSansBold';

  &:hover {
    cursor: pointer;
  }

  &--size-medium {
    padding: $button-medium-padding;
  }

  &--size-big {
    font-size: $button-big-font-size;
    padding: $button-big-padding;
  }

  &--size-giant {
    font-size: $button-giant-font-size;
    padding: $button-giant-padding;
  }

  &--style-secondary {
    background: $button-secondary-background-color;
    border: $button-secondary-border;
    color: $button-secondary-text-color;
  }

  &--style-transparent {
    background-color: transparent;
  }

  &--focus {
    background-color: $button-focus-background-color;
    border-color: $button-focus-border-color;
  }

  &--disabled {
    background: $color-gray-dark;
    border-color: $color-gray-dark;

    &.button--focus {
      border-color: $natural-gray;
    }
  }
  
  &--style-outline,
  &--style-outline-round {
    border: 0.5vh solid white;
    background: transparent;

    &.button--focus {
      background: white;

      & .icon {
        // border: $color-brand-primary;
        fill: $color-brand-primary;
      }
    } 
  }
  
  &--style-outline-round {
    border-radius: 50%;
    height: 8vh;
    width: 8vh;
  }

  &--block-full,
  &--block-half {
    border: none;
    border-radius: 0;
    margin: 0;    
  }

  &--block-full {
    width: 100%;
  }

  &--block-half {
    width: 50%;
  }

  &--block-default {
      margin-right: 1vw;
  }
}