.form__group {
  margin: $margin-form-group;
}

.form__input {
  color: $color_secondary;
  font-size: 3.5vh;
  font-family: "DMSansRegular";
  background-color: $input-background-color;
  border-radius: $input-border-radius;
  border: $input-border;
  padding: 0.5vh 1vh;
  width: 100%;

  &:focus {
    outline-offset: 0;
    outline: none;
  }

  &:hover:not( &--focus ) {
    border-color: $input-hover-not-focus-border-color;
  }

  &--focus {
    border-color: $input-focus-border-color;
  }

  &__big {
    padding: 1vh 2vh;
    height: 7vh;
  }
}


/* Change the background-color in autofill input */
.form__input:-webkit-autofill,
.form__input:-webkit-autofill:hover,
.form__input:-webkit-autofill:focus,
.form__input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 7vh $input-box-shadow-color inset !important;
}

/* Change text color in autofill input */
.form__input:-webkit-autofill {
  -webkit-text-fill-color: $color_secondary !important;
}